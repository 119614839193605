<script lang="ts" setup>
interface Menu {
  title: string
  isOpen: boolean
  link?: any
  child?: Array<{
    title: string
    link?: any
  }>
}

const menuLists: Ref<Menu[]> = ref([
  {
    title: '找生活',
    isOpen: false,
    child: [
      {
        title: 'hence, 雜誌',
        link: '/hence'
      },
      {
        title: 'zonezone 概念店',
        link: '/zonezone'
      }
    ]
  },
  {
    title: '找好物',
    isOpen: false,
    child: [
      {
        title: '線上購物',
        link: 'https://shop.hengstyle.com/'
      },
      {
        title: '選品服務',
        link: '/services/select'
      }
    ]
  },
  {
    title: '找服務',
    isOpen: false,
    child: [
      {
        title: '產品維修',
        link: '/services/repair'
      },
      {
        title: '保固註冊',
        link: '/services/warranty'
      },
      {
        title: '到府教學服務',
        link: '/services/inhome'
      },
      {
        title: '深層清潔申請',
        link: '/services/cleaning'
      },
      {
        title: '服務進度查詢',
        link: '/member/service'
      },
    ]
  },
  {
    title: '會員計劃',
    link: '/membership',
    isOpen: false
  },
  {
    title: '2024光織島',
    link: 'https://www.hengstyle.com/events/sparkleisland',
    isOpen: true
  },
  {
    title: '關於恆隆行',
    isOpen: false,
    child: [
      {
        title: '品牌故事',
        link: '/brand-story'
      },
      {
        title: '代理品牌',
        link: '/brands'
      },
      {
        title: '最新消息',
        link: '/news'
      },
      // {
      //   title: '永續生活',
      //   link: '/sustainability'
      // },
      {
        title: '人才發展',
        link: '/talent-development'
      }
    ]
  }
])

const handleChildMenuToggle = (data: any) => {
  data.isOpen = !data.isOpen
}
</script>

<template>
  <div>
    <ul class="menu">
      <li v-for="(item, index) in menuLists" :key="index">
        <div class="menu__item">
          <template v-if="item.child">
            <div class="menu__title cursor-pointer" :class="{ isOpen: item.isOpen }"
              @click="handleChildMenuToggle(item)">
              {{ item.title }}
              <div class="menu__title-arrow">
                <svg-icon name="icon-arrow-down" />
              </div>
            </div>
            <transition>
              <div class="menu__child" v-show="item.isOpen">
                <div class="menu__child__inner">
                  <nuxt-link v-for="(child, childIndex) in item.child" :key="childIndex" :to="child.link"
                    :title="child.title" class="menu__child__title">
                    {{ child.title }}
                  </nuxt-link>
                </div>
              </div>
            </transition>
          </template>
          <template v-else>
            <nuxt-link class="menu__title" :to="item.link" :title="item.title">
              {{ item.title }}
            </nuxt-link>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  &__title {
    display: flex;
    align-items: center;
    padding-top: toRem(10);
    padding-bottom: toRem(10);

    &-arrow {
      width: toRem(24);
      height: toRem(24);
      margin-left: auto;
      color: $color-gray-3;
      transition: all 0.5s ease-in-out;
    }

    &.isOpen {
      .menu {
        &__title {
          &-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__child {
    padding-top: toRem(12);
    padding-bottom: toRem(12);
    background-color: $color-brand-4;

    &__title {
      display: block;
      padding: toRem(6) toRem(20);
    }
  }
}
</style>
